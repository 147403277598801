import 'UI/watermark';

import { ontapCallback } from 'Auth/onetap';
import { delegate, q, ww } from 'bobjoll/ts/library/dom';
import { sessionStorage } from 'bobjoll/ts/library/storage';
import SessionErrors from 'Config/gr.errors';
import { randomAvatar } from 'Library/helpers';
import { isMobile, isOptimalBrowser, mobileOrientationChange } from 'Library/helpers/device';
import { showCampaignBanner } from 'Partials/campaign-php/campaign.helpers';
import ga from 'Partials/tracker';
import { setGrLgUri } from 'Partials/user/user.helpers';
import newsletterCallback from 'Project/ts/auth/newsletter';
import { Session } from 'Project/ts/auth/session';
import { Showcase } from 'Project/ts/partials/showcase';
import { runScript } from 'Project/ts/ui/lazyload-script';

import { Adobe } from './common/adobe';
import { cookie } from './library/cookie';
/**
 * Hide option menu by version browser
 */
import { SwiperInitialize } from './library/swiper';
import { gtm } from './partials/tracker-gtm';
import { run } from './ui/lazyload';

require('bobjoll/ts/library/common');
require('Project/ts/templates/helpers');

/**
 * Global variables
 */
ww.FACEBOOK_APP_ID = '117646314917595';
ww.FACEBOOK_PIXEL_ID = '1470657753234558';
ww.GOOGLE_CLIENT_ID = '645947318004-vpkoa2nspdlg3ehvrelfkaqekvjqmo4q.apps.googleusercontent.com';
ww.SPONSOR_NAME = FEATURE_SPONSOR_ISTOCK ? 'istock' : FEATURE_SPONSOR_SHUTTERSTOCK ? 'shutterstock' : 'adobestock';
ww.AdobeHelpers = Adobe.helpers;

ww.CampaignHelpers = {
    showBanner: showCampaignBanner,
};

ww.gr = new Session({
    defaultAvatar: randomAvatar(),
    recaptcha: navigator.userAgent.match(/Ghost Inspector/gi) ? false : true,
    recaptchaSitekey: RE_CAPTCHA_KEY_INVISIBLE,
    errorCodes: SessionErrors,
    newsletterCallback: newsletterCallback,
});

ww.ga2 = ga;
ww.gtm = gtm;
ww.Lazyload = { run, runScript };
ww.SwiperInitialize = SwiperInitialize;
ww.randomAvatarMethod = randomAvatar;
ww.Showcase = Showcase;
ww.removeTagFromMenu = false;
ww.delegate = delegate;
ww.setGrLgUri = setGrLgUri;
ww.ontapCallback = ontapCallback;

OPTIMAL_BROWSER = isOptimalBrowser();
IS_MOBILE = isMobile();

sessionStorage.setItem('user', 'country', USER_COUNTRY_CODE);

/*CHECKOUT-4457 if ((!IS_MOBILE || FEATURE_MOBILE_ADS_BY_LANGUAGE) && !FEATURE_DISABLE_ADS) {
    if (FEATURE_ENABLE_SSEO) {
        AdsSsmCodes.addLink();
        AdsSsmCodesScript.addScript();
    } else {
        AdsGPT.addLink();
        AdsGPTScript.addScript();
    }
}*/

if (cookie.getItem('gr_remember') && ww.gr && !ww.gr.user) {
    fetch('/profile/request/login', {
        credentials: 'include',
        method: 'GET',
    })
        .then(async data => {
            const user = await data.json();
            ww.gr.triggerLoginRemember(user.data);
            ww.gr.updateUser();
            ww.gr.updateUI();
        })
        .catch(() => {
            ww.gr.doLogout();
        });
}

if (!OPTIMAL_BROWSER) {
    q('#link-editor-freepik')?.classList.add('hide');
}

/**
 * Load modal
 */
(async () => {
    ww.ModalInstance = await (await import('Partials/modal')).Modal;
})();

/**
 * Mobile events
 */
IS_MOBILE && mobileOrientationChange();

const createUUIDForLegacyBrowsers = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        var r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

declare global {
    interface Crypto {
        randomUUID(): string;
    }
}

const cryptoWithUUID: Crypto | null = crypto;

TRACKING_UUID_SEARCH = cryptoWithUUID != null ? cryptoWithUUID.randomUUID() : createUUIDForLegacyBrowsers();
